import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Table } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import * as S from '../RequestLoanManagement.styles';
import { formatNumberWithCommas } from '@app/utils/utils';

// import { CreditScoring } from '@app/components/common/CreditScoring/CreditScoring';
import { StatusesText } from '@app/components/common/Statuses/Statuses';

import { FormSearchLoanRequest } from './FormSearchLoan';
import Avatar from '../../../../../../assets/icons/avatar.svg';
import ModalAccept from './ModalAccept';
import ModalReject from './ModalReject';
import { ApprovalRequestLoanManagementApi, getRequestLoanManagementApi } from '../RequestLoanManagement.api';

import { DataResponseListRequestLoanType, DataTypeTable, FilterParamsRequestID } from '../interface';
import { ColumnsType } from 'antd/lib/table';
import { RequestLoanContext } from '../Context';
import { updateFiltersActionRejectRequestLoan, updateFiltersRequestLoan, updateModalsRequestLoan } from '../Action';
import { notificationController } from '@app/controllers/notificationController';
import _ from 'lodash';
import { queryStringParams } from '@app/controllers/queryStringParams';
import UserNameAvatarColumn from '@app/components/common/UserNameAvatarColumn/UserNameAvatarColumn';
import ActionColumn from '@app/components/common/ActionColumn/ActionColumn';
import ModalCreateLoan from './ModalCreateLoan';
import { CreditScoring } from '@app/components/common/CreditScoring/CreditScoring';
import TableColumnTime from '@app/components/common/TableColumnTime/TableColumnTime';

export default function TableRequestLoanManagement() {
  const [filterRequestID, setfilterRequestID] = useState<FilterParamsRequestID>({
    code: '',
  });

  const [isModalOpenModalAccept, setIsModalOpenModalAccept] = useState<boolean>(false);
  const [RecordRow, setRecordRow] = useState<any>();
  const { stateRequestLoan, dispatchRequestLoan } = useContext(RequestLoanContext);
  const [checkLoading, setcheckLoading] = useState(false);
  const [dataListRequestLoan, setDataListRequestLoan] = useState<DataResponseListRequestLoanType>();
  const callApiRequestLoan = useCallback(async () => {
    setcheckLoading(true);

    await getRequestLoanManagementApi(queryStringParams(stateRequestLoan.filters))
      .then((res) => {
        res && res?.data && setDataListRequestLoan(res);
        setcheckLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setcheckLoading(false);
      });
  }, [stateRequestLoan.filters]);

  const callApiRequestLoanApproval = useCallback(
    async (filterRequestID: FilterParamsRequestID) => {
      setcheckLoading(true);

      await ApprovalRequestLoanManagementApi(filterRequestID)
        .then((res) => {
          callApiRequestLoan();
          if (res?.error) {
            setcheckLoading(false);
            // notificationController.error({ message: _.get(res?.message, `Approval fail`, `${res?.message}`) });
          } else {
            notificationController.success({ message: `Duyệt thành công` });
            setcheckLoading(false);
          }
        })
        .catch((err) => {
          setcheckLoading(false);
          notificationController.error({ message: _.get(err, `Approval fail`, `${err} err API`) });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterRequestID],
  );

  const showModalAccept = (value: DataTypeTable) => {
    setfilterRequestID({ ...filterRequestID, code: value?.code?.toString() });
    setIsModalOpenModalAccept(true);
    setRecordRow(value);
  };

  const handleOkModalAccept = () => {
    setIsModalOpenModalAccept(false);
    callApiRequestLoanApproval(filterRequestID);
    callApiRequestLoan();
  };

  const handleCancelModalAccept = () => {
    setIsModalOpenModalAccept(false);
  };

  const showModalReject = (value: DataTypeTable) => {
    dispatchRequestLoan(updateFiltersActionRejectRequestLoan({ ...stateRequestLoan.filterRequestReject, code: value?.code?.toString() }));
    setRecordRow(value);
    dispatchRequestLoan(updateModalsRequestLoan({ ...stateRequestLoan.modals, isModalOpenModalReject: true }));
  };

  // const handleOkModalReject = () => {
  //   setIsModalOpenModalReject(false);
  //   // callApiRequestLoanReject(stateRequestLoan?.filterRequestReject);
  // };

  // const handleCancelModalReject = () => {
  //   setIsModalOpenModalReject(false);
  // };

  useEffect(() => {
    callApiRequestLoan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRequestLoan.filters]);
  const columns: ColumnsType<DataTypeTable> = [
    {
      width: '230px',
      title: 'ID khoản vay',
      key: 'requestCode1',
      dataIndex: 'code',
      render: (value: string) => <a>{value ? value : ''}</a>,
    },
    {
      width: '204px',
      title: 'Tên khách hàng',
      key: 'userFullName',
      dataIndex: 'userFullName',
      render: (value: string) => {
        return <UserNameAvatarColumn avatar={Avatar} name={value ? value : ''}></UserNameAvatarColumn>;
      },
    },
    {
      width: '130px',
      title: 'Điểm Tín dụng KH',
      key: 'userScore',
      dataIndex: 'userScore',
      render: (value: any) => {
        return <div>{value ? <CreditScoring score={value && value} /> : ''}</div>;
      },
    },
    {
      width: '130px',
      title: 'Điểm Tín dụng ĐH',
      key: 'orderScore',
      dataIndex: 'orderScore',
      render: (value: any) => {
        return <div>{value ? <CreditScoring score={value && value} /> : ''}</div>;
      },
    },
    {
      width: '92px',
      title: 'Level',
      key: 'Level',

      render: (value: DataTypeTable) => (
        <div>
          {(value.level && value.level == 'LEVEL_0' ? '0' : '') ||
            (value.level && value.level == 'LEVEL_1' ? '1' : '') ||
            (value.level && value.level == 'LEVEL_2' ? '2' : '') ||
            (value.level && value.level == 'LEVEL_3' ? '3' : '') ||
            (value.level && value.level == 'LEVEL_4' ? '4' : '')}
        </div>
      ),
    },

    {
      width: '180px',
      title: 'Ngày gửi request',
      key: 'createdDate',
      dataIndex: 'createdDate',
      sorter: (a: any, b: any) => a.age - b.age,

      render: (value: string) => <TableColumnTime value={value ? value : ''}></TableColumnTime>,
    },
    {
      width: '150px',
      title: 'Số tiền vay',
      key: 'loanAmount',
      dataIndex: 'loanAmount',
      align: 'right',
      render: (value: number) => {
        return <div>{value ? formatNumberWithCommas(value) : ''}</div>;
      },
    },
    {
      width: '120px',
      title: 'Đối tác liên kết',
      key: 'partnerName',
      dataIndex: 'partnerName',
    },
    {
      width: '120px',
      title: 'Mục đích vay',
      key: 'purpose',
      dataIndex: 'purpose',
      render: (value: string) => {
        return <div>{value}</div>;
      },
    },
    {
      width: '146px',
      title: 'REFID',
      key: 'referenceId',
      dataIndex: 'referenceId',

      render: (value: number) => <a className="cursor-pointer-none">{value ? value : ''}</a>,
    },

    {
      width: '150px',
      title: 'Hình thức vay',
      key: 'loanMethod',
      dataIndex: 'loanMethod',
    },

    {
      width: '150px',
      title: 'Lãi',
      key: 'duration',
      dataIndex: 'duration',
      align: 'right',
      render: (value: number) => {
        return <div>{value ? formatNumberWithCommas(value) : ''}</div>;
      },
    },

    {
      width: '150px',
      title: 'Phí dự kiến',
      align: 'right',
      key: 'feeAmount',
      dataIndex: 'feeAmount',
      render: (value: number) => {
        return <div>{value ? formatNumberWithCommas(value) : ''}</div>;
      },
    },
    {
      width: '200px',
      title: 'Người tạo',
      key: 'originationChannel',

      render: (value: DataTypeTable) => {
        return <div>{value?.originationChannel ? value?.originationChannel : '-'}</div>;
      },
    },

    {
      width: '155px',
      title: 'Trạng thái BF',
      key: 'status',
      fixed: 'right',

      dataIndex: 'status',
      render: (value) => {
        return <StatusesText status={value} context="LOAN"></StatusesText>;
      },
    },
    {
      width: '100px',
      title: 'Hành động',
      key: 'requestId',
      fixed: 'right',
      render: (value: DataTypeTable) => {
        return (
          <ActionColumn
            status={value?.status}
            handleApproval={() => showModalAccept(value)}
            handleRejected={() => showModalReject(value)}
            actionApproval={true}
            actionReject={true}
            actionWatch={true}
          ></ActionColumn>

          /* <div className="style-text-action-watch">Xem</div>
            <Divider type="vertical" />
            {value?.status == 'WAIT_FOR_APPROVE' ? (
              <div className="style-text-action-approval" onClick={() => showModalAccept(value)}>
                Duyệt
              </div>
            ) : (
              <div className="style-text-action-approval-disable">Duyệt</div>
            )}
            <Divider type="vertical" />
            {value.status == 'WAIT_FOR_APPROVE' ? (
              <div className="style-text-action-reject" onClick={() => showModalReject(value)}>
                Từ chối
              </div>
            ) : (
              <div className="style-text-action-reject-disable">Từ chối</div>
            )} */
        );
      },
    },
  ];

  return (
    <GS.WrapperOverrideInput>
      <S.ContainerRequestLoan className="container-request-loan">
        <ModalAccept RecordRow={RecordRow} isModalOpenAccept={isModalOpenModalAccept} handleOkAccept={handleOkModalAccept} handleCancelAccept={handleCancelModalAccept}></ModalAccept>
        {dataListRequestLoan && <ModalReject callApiRequestLoan={callApiRequestLoan} dataListRequestLoan={dataListRequestLoan && dataListRequestLoan}></ModalReject>}
        <div className="box-content">
          <FormSearchLoanRequest></FormSearchLoanRequest>
          <Table
            style={{ padding: '16px 24px 24px 24px' }}
            scroll={{ x: 2530 }}
            rowSelection={{ type: 'checkbox' }}
            dataSource={dataListRequestLoan?.data}
            columns={columns}
            sticky={true}
            rowKey="code"
            loading={checkLoading}
            pagination={{
              current: dataListRequestLoan?.pageIndex,
              pageSize: dataListRequestLoan?.pageSize,
              total: dataListRequestLoan?.total,
              onChange: (page: number, pageSize: number) => {
                dispatchRequestLoan(updateFiltersRequestLoan({ ...stateRequestLoan?.filters, pageIndex: page, pageSize: pageSize }));
              },
            }}
          />
        </div>
        <ModalCreateLoan callApiRequestLoan={callApiRequestLoan}></ModalCreateLoan>
      </S.ContainerRequestLoan>
    </GS.WrapperOverrideInput>
  );
}
