import { ReactComponent as IconUploadPDF } from '@app/assets/icons/icon-upload-pdf.svg';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setBreadcrumb, setBreadcrumbTitle } from '@app/store/slices/breadcrumbSlice';
import * as GS from '@app/styles/GlobalStyle';
import { useContext, useEffect, useState } from 'react';
import { LoanListContext, LoanListProvider } from '../Context';
import * as S from '../Loan.styles';

import BtnCreateStyle from '@app/components/common/BtnCreateStyle/BtnCreateStyle';
import { Col, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
import { updateStateModalDisbursement } from '../Action';
import DebtSection from '../ComponentLoan/DebtSection';
import DisbursementSection from '../ComponentLoan/DisbursementSection';
import InforGeneralSection from '../ComponentLoan/InforGeneralSection';
import InforPaymentSection from '../ComponentLoan/InforPaymentSection';
import TimelineSection from '../ComponentLoan/TimelineSection';
import UserInforSection from '../ComponentLoan/UserInforSection';
import { detailLoanApi } from '../Loan.api';
import { DataLoanDetailType } from '../interface';
import ModalAddImageMoneyBorrow from './ModalAddImageMoneyBorrow';
import ModalDetailImageMoneyBorrow from './ModalDetailImageMoneyBorrow';
import ModalUpdateLoan from './ModalUpdateLoan';

function ContentDetailLoan() {
  const { stateLoanList, dispatchLoanList } = useContext(LoanListContext);
  const { loancode } = useParams();
  const [dataDetailLoan, setdataDetailLoan] = useState<DataLoanDetailType>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          name: 'Quản lý khoản vay',
        },
        {
          name: 'Danh sách Khoản vay ',
          url: '/customers/loan',
        },
        {
          name: `${loancode}`,
        },
      ]),
    );

    dispatch(setBreadcrumbTitle(`${loancode}`));

    return () => {
      dispatch(setBreadcrumb([]));
      dispatch(setBreadcrumbTitle(`${loancode}`));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchLoanList, dispatch]);

  function callApiDetailLoan() {
    detailLoanApi(loancode)
      .then((res) => {
        setdataDetailLoan(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    callApiDetailLoan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loancode]);

  console.log('dataDetailLoan', dataDetailLoan);

  function openModalUpdateloan() {
    dispatchLoanList(updateStateModalDisbursement({ ...stateLoanList.stateModal, stateModalUpdateLoan: true }));
  }

  function handleOpenModalDetailImage() {
    dispatchLoanList(updateStateModalDisbursement({ ...stateLoanList.stateModal, openModalDetailImage: true }));
  }

  function handleOpenModalAddImage() {
    dispatchLoanList(updateStateModalDisbursement({ ...stateLoanList.stateModal, openModalAddImage: true }));
  }

  return (
    <GS.WrapperOverrideInput style={{ background: '#12161C' }}>
      <S.TimelineStyle>
        <TimelineSection dataDetailLoan={dataDetailLoan}></TimelineSection>
        <S.DflexFlexEnd>
          {dataDetailLoan && dataDetailLoan?.status == 'ACTIVE' ? <BtnCreateStyle label="Cập nhật khoản vay" onClick={openModalUpdateloan}></BtnCreateStyle> : null}
          {/* <Button icon={<AiOutlineExport />}>Xuất PDF</Button> */}
        </S.DflexFlexEnd>
      </S.TimelineStyle>
      <S.LayoutInfor>
        <Col className="layout-left" span={17}>
          <UserInforSection dataDetailLoan={dataDetailLoan}></UserInforSection>
          <DisbursementSection callApiDetailLoan={callApiDetailLoan} dataLoanDetail={dataDetailLoan}></DisbursementSection>
          <DebtSection callApiDetailLoan={callApiDetailLoan} dataDetailLoan={dataDetailLoan}></DebtSection>
          <div className="section-image-borrow-loan">
            <div className="label">Ảnh xác nhận ứng vốn</div>
            <div className="proof">
              {dataDetailLoan?.loanEvidence ? (
                <div className="value value-yellow name-evidence" onClick={handleOpenModalDetailImage}>
                  <IconUploadPDF style={{ marginRight: '5px' }}></IconUploadPDF>
                  <Tooltip placement="top" title={<span>{dataDetailLoan?.loanEvidence ? dataDetailLoan?.loanEvidence : ''}</span>}>
                    {dataDetailLoan?.loanEvidence ? dataDetailLoan?.loanEvidence : null}
                  </Tooltip>
                </div>
              ) : (
                <BtnCreateStyle label="Thêm" onClick={handleOpenModalAddImage}></BtnCreateStyle>
              )}
            </div>
          </div>
        </Col>
        <Col className="layout-right" span={7}>
          <InforGeneralSection dataDetailLoan={dataDetailLoan}></InforGeneralSection>
          <InforPaymentSection dataDetailLoan={dataDetailLoan}></InforPaymentSection>
        </Col>
      </S.LayoutInfor>
      {/* <div style={{ background: '#1A1F25' }}>
        <S.H2>Danh sách khoản vay</S.H2>
      </div> */}
      <ModalUpdateLoan callApiDetailLoan={callApiDetailLoan} dataDetailLoan={dataDetailLoan}></ModalUpdateLoan>
      <ModalDetailImageMoneyBorrow callApiDetailLoan={callApiDetailLoan} data={dataDetailLoan && dataDetailLoan}></ModalDetailImageMoneyBorrow>
      <ModalAddImageMoneyBorrow callApiDetailLoan={callApiDetailLoan} data={dataDetailLoan && dataDetailLoan}></ModalAddImageMoneyBorrow>
    </GS.WrapperOverrideInput>
  );
}

export default function DetailLoan() {
  return (
    <LoanListProvider>
      <ContentDetailLoan />
    </LoanListProvider>
  );
}
