import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setBreadcrumb, setBreadcrumbTitle } from '@app/store/slices/breadcrumbSlice';
import * as GS from '@app/styles/GlobalStyle';
import { useEffect, useState } from 'react';
import { LoanListProvider } from '../Context';
import * as S from '../Loan.styles';

import { formatNumberWithCommas } from '@app/utils/utils';
import { Col, Table } from 'antd';
import { useParams } from 'react-router-dom';
import { StatusesText } from '../../../../../../components/common/Statuses/Statuses';
import DisbursementSection from '../ComponentLoan/DisbursementSection';
import InfoConsolidatedGeneralSection from '../ComponentLoan/InfoConsolidatedGeneralSection';
import InforPaymentConsolidated from '../ComponentLoan/InforPaymentConsolidated';
import UserInforSection from '../ComponentLoan/UserInforSection';
import { detailConsolidatedLoan } from '../Loan.api';

const ContentDetailLoanConsolidated = () => {
  const [dataDetailLoan, setDataDetailLoan] = useState<any>();
  const { loancode } = useParams();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          name: 'Quản lý khoản vay',
        },
        {
          name: 'Danh sách khoản vay',
          url: '/customers/loans-consolidated',
        },
        {
          name: `${loancode}`,
        },
      ]),
    );

    dispatch(setBreadcrumbTitle(`${loancode}`));

    return () => {
      dispatch(setBreadcrumb([]));
      dispatch(setBreadcrumbTitle(`${loancode}`));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  function callApiDetailLoan() {
    detailConsolidatedLoan(loancode)
      .then((res) => {
        setDataDetailLoan(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    callApiDetailLoan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loancode]);

  console.log('dataDetailLoan', dataDetailLoan);

  const columns = [
    {
      width: '230px',
      title: 'ID khoản vay',
      key: 'loanId',
      dataIndex: 'loanId',
      render: (loanId: any) => (
        <a href={`/customers/loan/${loanId}`} style={{ color: '#F2C015' }}>
          {loanId ? loanId : '---'}
        </a>
      ),
    },
    {
      width: '204px',
      title: 'REF ID',
      key: 'orderId',
      dataIndex: 'orderId',

      render: (orderId: any) => {
        return <span>{orderId ? orderId : '---'}</span>;
      },
    },
    {
      width: '130px',
      title: 'Đã giải ngân',
      key: 'disbursedAmount',
      dataIndex: 'disbursedAmount',
      render: (disbursedAmount: any) => {
        return <span>{disbursedAmount ? formatNumberWithCommas(disbursedAmount) : '---'}</span>;
      },
    },
    {
      width: '130px',
      title: 'Đã thanh toán',
      key: 'paymentAmount',
      dataIndex: 'paymentAmount',
      render: (paymentAmount: any) => {
        return <span>{paymentAmount ? formatNumberWithCommas(paymentAmount) : '---'}</span>;
      },
    },
    {
      width: '130px',
      title: 'Trạng thái khoản vay',
      key: 'loanStatus',
      dataIndex: 'loanStatus',
      render: (loanStatus: any) => {
        return <StatusesText context="LOAN" status={loanStatus} />;
      },
    },
  ];

  return (
    <GS.WrapperOverrideInput style={{ background: '#12161C' }}>
      <S.LayoutInfor>
        <Col className="layout-left" span={17}>
          <UserInforSection dataDetailLoan={dataDetailLoan}></UserInforSection>
          <div className="section-disbursement">
            <div className="title-disbursement">Danh sách đơn hàng</div>

            <Table dataSource={dataDetailLoan?.loanOrderPairs || []} columns={columns} sticky={true} rowKey="id" pagination={false} />
          </div>

          <DisbursementSection callApiDetailLoan={callApiDetailLoan} dataLoanDetail={dataDetailLoan}></DisbursementSection>
        </Col>
        <Col className="layout-right" span={7}>
          <InfoConsolidatedGeneralSection dataDetailLoan={dataDetailLoan} />
          <InforPaymentConsolidated dataDetailLoan={dataDetailLoan} />
        </Col>
      </S.LayoutInfor>
    </GS.WrapperOverrideInput>
  );
};

export default function DetailLoan() {
  return (
    <LoanListProvider>
      <ContentDetailLoanConsolidated />
    </LoanListProvider>
  );
}
