import React, { useContext, useEffect, useState } from 'react';
import * as S from '../Policy.style';
import { PolicyContext } from '../Context';
import FooterModal from '@app/components/common/FooterModal/FooterModal';
import { updateModalsPolicy } from '../Action';
import { Form } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import { DataDetailPolicy } from '../interface';
import { formatNumberWithCommas } from '@app/utils/utils';
import { getDetailPolicyApi } from '../Policy.api';

type Props = {
  dataPolicy?: DataDetailPolicy | undefined;
};

export default function ModalDetailPolicy(props: Props) {
  const { statePolicy, dispatchPolicy } = useContext(PolicyContext);
  const [form] = Form.useForm();
  const [data, setdata] = useState<DataDetailPolicy>();

  function callApiGetDetail() {
    if (props?.dataPolicy) {
      getDetailPolicyApi(props?.dataPolicy?.code)
        .then((res) => {
          setdata(res);
        })
        .catch((err) => {
          console.log(err);
          setdata(undefined);
        });
    }
  }

  useEffect(() => {
    callApiGetDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.dataPolicy]);

  const validateMessages = {
    required: 'Cần nhập ${label}!',
    types: {
      email: '${label} is not a valid email!',
      string: '${label} không hợp lệ!',
    },
  };

  function handleCancel() {
    dispatchPolicy(updateModalsPolicy({ ...statePolicy?.modalsPolicy, modalDetail: false }));
  }

  return (
    <S.ModalDetailPolicy title={'Chi tiết chính sách'} open={statePolicy?.modalsPolicy?.modalDetail && statePolicy?.modalsPolicy?.modalDetail} footer={null} onCancel={handleCancel}>
      <Form form={form} name="basic" validateMessages={validateMessages}>
        <GS.WrapperOverrideInput>
          <div>
            <div className="content-header">
              <div className="item-form">
                <div className="label">Tên chính sách</div>
                <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                  {props?.dataPolicy?.name ? props?.dataPolicy?.name : '-'}
                </div>
              </div>
            </div>
            <h2 className="title-modal">Tỷ lệ phí và lãi theo tỷ lệ cho vay</h2>
            <div className="content-body">
              <div className="col-1">
                <div className="item-form">
                  <div className="label">Tỉ lệ cho vay mặc định (%)</div>
                  <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                    {props?.dataPolicy?.maximumLoanRate ? props?.dataPolicy?.maximumLoanRate + ' %' : 0}
                  </div>
                </div>
                {data && data?.policySettings.length > 0
                  ? data?.policySettings.map((item: any) => (
                      <div key={item?.id} className="item-form">
                        <div className="label">
                          Tỷ lệ cho vay: &gt;{item?.lowerLoanRate && item?.lowerLoanRate} và &lt;={item?.higherLoanRate && item?.higherLoanRate}%
                        </div>
                        <S.lineSpecial>
                          <div className="col-1">
                            <div className="label-special">Lãi/Năm(%)</div>
                            <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                              {item?.interestRate ? item?.interestRate + ' %' : 0}
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="label-special">Phí(%)</div>
                            <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                              {item?.feeRate ? item?.feeRate + ' %' : 0}
                            </div>
                          </div>
                        </S.lineSpecial>
                      </div>
                    ))
                  : null}
              </div>
              <div className="col-2">
                <div className="item-line">
                  <div className="item-form">
                    <div className="label">Số tiền cho vay tối đa trên khoản vay(đ)</div>
                    <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                      {props?.dataPolicy?.maximumLoanAmount ? formatNumberWithCommas(props?.dataPolicy?.maximumLoanAmount) + ' đ' : 0}
                    </div>
                  </div>
                </div>
                <div className="item-line">
                  <div className="item-form">
                    <div className="label">Số tiền duyệt tự động(đ)(&lt;=)</div>
                    <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                      {props?.dataPolicy?.loanAmountAutoApprove ? formatNumberWithCommas(props?.dataPolicy?.loanAmountAutoApprove) + ' đ' : 0}
                    </div>
                  </div>
                </div>
                <div className="item-line">
                  <div className="item-form">
                    <div className="label">Điểm tín dụng tối thiểu của đơn hàng(điểm)</div>
                    <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                      {props?.dataPolicy?.creditScore ? props?.dataPolicy?.creditScore : 0}
                    </div>
                  </div>
                </div>

                <div className="item-line">
                  <div className="item-form">
                    <div className="label">Ngày ân hạn(Ngày)</div>
                    <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                      {props?.dataPolicy?.gracePeriod ? props?.dataPolicy?.gracePeriod + ' Ngày' : 0}
                    </div>
                  </div>
                </div>

                <div className="item-line">
                  <div className="item-form">
                    <div className="label">Số ngày/ kỳ(Ngày)</div>
                    <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                      {props?.dataPolicy?.dayPerPeriod ? props?.dataPolicy?.dayPerPeriod + ' Ngày' : 0}
                    </div>
                  </div>
                </div>
                <div className="item-line">
                  <div className="item-form">
                    <div className="label">Phí quá hạn kỳ 1(%)</div>
                    <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                      {props?.dataPolicy?.lateFeeFirstTerm ? props?.dataPolicy?.lateFeeFirstTerm + ' %' : 0}
                    </div>
                  </div>
                </div>
                <div className="item-line">
                  <div className="item-form">
                    <div className="label">Phí tăng sau mỗi kỳ(%)</div>
                    <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                      {props?.dataPolicy?.increasingFeePerPeriod ? props?.dataPolicy?.increasingFeePerPeriod + ' %' : 0}
                    </div>
                  </div>
                </div>
                <div className="item-line">
                  <div className="item-form">
                    <div className="label">Số ngày bị thu hồi(Ngày)</div>
                    <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                      {props?.dataPolicy?.dayRecall ? props?.dataPolicy?.dayRecall + ' Ngày' : 0}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <FooterModal handleClickCancel={handleCancel} type={undefined} padding="24px 0px 0px 0px" labelCancel="Đóng"></FooterModal>
          </div>
        </GS.WrapperOverrideInput>
      </Form>
    </S.ModalDetailPolicy>
  );
}
