import React, { useContext, useEffect, useState } from 'react';
import * as S from '../Partner.style';
import { PartnerContext } from '../Context';
import FooterModal from '@app/components/common/FooterModal/FooterModal';
import { updateModalsPartner } from '../Action';
import { Form, Input, UploadFile, message } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import { ReactComponent as IconUploadFile } from '@app/assets/icons/icon-uploadfile.svg';
import ImgCrop from 'antd-img-crop';
import { EditPartnerApi } from '../Partner.api';
import { notificationController } from '@app/controllers/notificationController';
import Upload, { RcFile, UploadProps } from 'antd/lib/upload';
import { UploadImgApi, getImg } from '@app/api/UploadFIle.api';
import { DataTablePartner } from '../interface';
import IconImg from '@app/assets/icons/icon-pin-img.svg';
import { DeleteOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { queryStringParams } from '@app/controllers/queryStringParams';

type Props = {
  dataDetail?: DataTablePartner | undefined;
  callApiDetailPartner?: () => void;
};

export default function ModalEditPartner(props: Props) {
  const [checkDelete, setcheckDelete] = useState(false);
  const { statePartner, dispatchPartner } = useContext(PartnerContext);
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [saveStringImg, setsaveStringImg] = useState<string | undefined>(props?.dataDetail?.imageUrl && props?.dataDetail?.imageUrl);
  const [setsaveImgReal, setsetsaveImgReal] = useState();
  const { uuid } = useParams();
  const validateMessages = {
    required: 'Cần nhập ${label}!',
    types: {
      email: '${label} is not a valid email!',
      string: '${label} không hợp lệ!',
    },
  };
  function handleCancel() {
    dispatchPartner(updateModalsPartner({ ...statePartner?.modalsPartner, modalEdit: false }));
  }

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const { Dragger } = Upload;
  const propsUpdate: UploadProps = {
    onPreview: onPreview,
    name: 'file',
    multiple: true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    beforeUpload(file, FileList) {
      return false;
    },

    onChange(info: any) {
      const { status } = info.file;
      if (status !== 'uploading') {
        const formData = new FormData();

        console.log(info.file);
        formData.append('file', info.file);

        UploadImgApi(
          formData,
          queryStringParams({
            packageType: 'PARTNER',
            partnerUuid: undefined,
          }),
        )
          .then((res) => {
            if (!res.error) {
              message.success(`${info.file.name} tải lên thành công`);
              if (res) {
                setsaveStringImg(res?.key);
              }
            } else {
              setsaveStringImg('');
            }
          })
          .catch((err) => {
            message.error(`${err} Err 404`);
          });
      }
    },
  };

  const callApiGetImg = async () => {
    if (props?.dataDetail && props?.dataDetail?.imageUrl != '') {
      await getImg(props?.dataDetail?.imageUrl)
        .then((res) => {
          setsetsaveImgReal(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  function handleDelteImg() {
    setcheckDelete(true);
  }

  const onFinish = async (values: any) => {
    setloading(true);
    if (values && uuid && props?.dataDetail) {
      EditPartnerApi(uuid, {
        name: values?.name && values?.name,
        code: props?.dataDetail?.code,
        redirectUri: values?.redirectUri && values?.redirectUri,
        profilePartnerUri: values?.profilePartnerUri && values?.profilePartnerUri,
        image: saveStringImg ? saveStringImg : '',
        website: values?.website && values?.website,
        partnerMapping: values?.partnerMapping,
      })
        .then((res) => {
          dispatchPartner(updateModalsPartner({ ...statePartner?.modalsPartner, modalEdit: false }));
          if (res?.error) {
            setloading(false);
            props?.callApiDetailPartner;
          } else {
            setloading(false);
            notificationController.success({ message: `Sửa thành công` });
            props?.callApiDetailPartner;
          }
        })
        .catch(() => {
          notificationController.error({ message: `Something went wrong  Api` });
          setloading(false);

          dispatchPartner(updateModalsPartner({ ...statePartner?.modalsPartner, modalEdit: false }));
          props?.callApiDetailPartner;
        });
    }
  };

  useEffect(() => {
    if (props?.dataDetail) {
      form.setFieldsValue({
        name: props?.dataDetail?.name,
        code: props?.dataDetail?.code,
        redirectUri: props?.dataDetail?.redirectUri,
        profilePartnerUri: props?.dataDetail?.profilePartnerUri,
        image: '',
        website: props?.dataDetail?.website ? props?.dataDetail?.website : '',
        partnerMapping: props?.dataDetail?.partnerMapping,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.dataDetail]);

  useEffect(() => {
    if (props?.dataDetail) {
      callApiGetImg();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.dataDetail]);

  return (
    <S.ModalCreatePartner title={'Thông tin đói tác'} open={statePartner?.modalsPartner?.modalEdit} footer={null} onCancel={handleCancel}>
      <Form form={form} name="basic" onFinish={onFinish} validateMessages={validateMessages}>
        <GS.WrapperOverrideInput>
          <div>
            <div className="content-header">
              <div className="item-form">
                <div className="label">
                  Tên đối tác <span className="err-text">*</span>
                </div>
                <Form.Item name={'name'} label="Tên đối tác" rules={[{ required: true, type: 'string' }]}>
                  <Input type="string" size={'small'} className={'style-input-create'} />
                </Form.Item>
              </div>
              <div className="item-form">
                <div className="label">
                  Code <span className="err-text">*</span>
                </div>
                <Form.Item name={'code'} label="Code" rules={[{ required: true, type: 'string' }]}>
                  <Input disabled type="string" size={'small'} className={'style-input-create'} />
                </Form.Item>
              </div>
            </div>

            <div className="content-body">
              <div className="item-line">
                <div className="item-form">
                  <div>
                    <div className="label">
                      redirect_uri <span className="err-text">*</span>
                    </div>
                    <Form.Item name={'redirectUri'} label="redirect_uri" rules={[{ required: true, type: 'string' }]}>
                      <Input size={'small'} className={'style-input-create'} />
                    </Form.Item>
                  </div>
                  <div>
                    <div className="label">
                      profile_partner_uri <span className="err-text">*</span>
                    </div>
                    <Form.Item name={'profilePartnerUri'} label="profile_partner_uri" rules={[{ required: true, type: 'string' }]}>
                      <Input size={'small'} className={'style-input-create'} />
                    </Form.Item>
                  </div>
                  <div>
                    <div className="label">
                      Link web <span className="err-text">*</span>
                    </div>
                    <Form.Item name={'website'} label="Link web" rules={[{ required: true, type: 'string' }]}>
                      <Input size={'small'} className={'style-input-create'} />
                    </Form.Item>
                  </div>
                  <div>
                    <div className="label">
                      Tenant Code <span className="err-text">*</span>
                    </div>
                    <Form.Item name={'partnerMapping'} label="Tenant Code" rules={[{ required: true, type: 'string' }]}>
                      <Input size={'small'} className={'style-input-create'} />
                    </Form.Item>
                  </div>
                </div>
                <div className="item-form">
                  <div className="label">Image</div>
                  {checkDelete ? (
                    <div className="save-image box-img-content-style">
                      <ImgCrop>
                        <Dragger {...propsUpdate}>
                          <p className="ant-upload-drag-icon">
                            <IconUploadFile />
                          </p>
                          <p className="ant-upload-text">
                            Chọn hoặc kéo ảnh <span style={{ color: '#85A5FF' }}> hoá đơn chuyển khoản</span> vào đây để tải ảnh lên
                          </p>
                        </Dragger>
                      </ImgCrop>
                    </div>
                  ) : (
                    <div>
                      <div className="save-image box-img-content">
                        <div className="box-img" style={{ width: '100%', height: '100%' }}>
                          <img
                            className="style-img save-img"
                            src={
                              setsaveImgReal && setsaveImgReal
                                ? setsaveImgReal
                                : 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.freepik.com%2Ffree-photos-vectors%2Floading-screen&psig=AOvVaw0A7H7rBe68YOeNx2IeudOj&ust=1677059194591000&source=images&cd=vfe&ved=0CBAQjRxqFwoTCJD27sGqpv0CFQAAAAAdAAAAABAI'
                            }
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="title-section-img-common">
                        <div className="box-startend">
                          <img src={IconImg} alt="" />
                          <div className="name-img">{props?.dataDetail && props?.dataDetail?.imageUrl}</div>
                        </div>
                        <div>
                          <DeleteOutlined onClick={handleDelteImg} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <FooterModal handleClickCancel={handleCancel} handleLoading={loading} type="submit" padding="24px 0px 0px 0px" LabelSave="Lưu thay đổi" labelCancel="Huỷ"></FooterModal>
          </div>
        </GS.WrapperOverrideInput>
      </Form>
    </S.ModalCreatePartner>
  );
}
