import TableColumnTime from '@app/components/common/TableColumnTime/TableColumnTime';

// import { ReactComponent as IconUploadPDF } from '@app/assets/icons/icon-upload-pdf.svg';
import { StatusesText } from '@app/components/common/Statuses/Statuses';
import { DataLoanDetailType } from '../interface';

type Props = {
  dataDetailLoan: DataLoanDetailType | undefined;
};

export default function InfoConsolidatedGeneralSection(props: Props) {
  return (
    <div className="section-inforgeneral">
      <div className="title-inforgeneral">Thông tin khoản vay</div>
      <div className="content">
        <div className="box-content-loan">
          <div className="box-line margin-bottom">
            <div className="label">ID khoản vay gộp</div>
            <div className="value">{props?.dataDetailLoan?.code || '---'}</div>
          </div>
          <div className="box-line margin-bottom">
            <div className="label">Trạng thái khoản vay</div>
            <div className="value">{props?.dataDetailLoan?.status ? <StatusesText status={props?.dataDetailLoan?.status && props?.dataDetailLoan?.status} context="LOAN"></StatusesText> : '---'}</div>
          </div>

          <div className="box-line margin-bottom">
            <div className="label">Ngày gửi request</div>
            <div className="value">{props?.dataDetailLoan?.requestDate ? <TableColumnTime value={props?.dataDetailLoan ? props?.dataDetailLoan?.requestDate : '---'}></TableColumnTime> : '-'}</div>
          </div>
          <div className="box-line margin-bottom">
            <div className="label">Ngày duyệt</div>
            <div className="value">{props?.dataDetailLoan?.approvalDate ? <TableColumnTime value={props?.dataDetailLoan ? props?.dataDetailLoan?.approvalDate : '---'}></TableColumnTime> : '-'}</div>
          </div>
          <div className="box-line margin-bottom">
            <div className="label">Ngày giải ngân</div>
            <div className="value">
              {props?.dataDetailLoan?.disbursementDate ? <TableColumnTime value={props?.dataDetailLoan ? props?.dataDetailLoan?.disbursementDate : '---'}></TableColumnTime> : '-'}
            </div>
          </div>
          <div className="box-line margin-bottom">
            <div className="label">Quỹ/Nguồn vốn sở hữu</div>
            <div className="value">
              {props?.dataDetailLoan?.disbursementDate ? <TableColumnTime value={props?.dataDetailLoan ? props?.dataDetailLoan?.disbursementDate : '---'}></TableColumnTime> : '-'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
