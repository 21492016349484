import React, { useContext, useEffect, useState } from 'react';
import * as S from '../Capital.styles';
import * as GS from '@app/styles/GlobalStyle';
import { ReactComponent as IconUploadFile } from '@app/assets/icons/icon-uploadfile.svg';
import ImgCrop from 'antd-img-crop';
import IconImg from '@app/assets/icons/icon-pin-img.svg';

import { CapitalContext } from '../Context';
import { updateModals } from '../Action';
import { Button, Checkbox, Col, Form, Input, message, Row, TabsProps, Upload, UploadFile, UploadProps } from 'antd';
import FooterModal from '@app/components/common/FooterModal/FooterModal';
import { getImg, UploadImgApi } from '@app/api/UploadFIle.api';
import ContentMoneyTab from './ContentMoneyTab';
import { ResponseApiDetail } from '../interface';
import { notificationController } from '@app/controllers/notificationController';
import _ from 'lodash';
import { updateCapitalApi } from '../Capital.api';
import { RcFile } from 'antd/lib/upload';
import { DeleteOutlined } from '@ant-design/icons';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { getProductApi } from '../../Product/Product.api';
import { queryStringParams } from '@app/controllers/queryStringParams';
type Props = {
  dataDetail: ResponseApiDetail | null;
  callApiDetailCapital: () => void;
};
export default function ModalEditCapital(props: Props) {
  const [saveStringImg, setsaveStringImg] = useState<string | null>(null);
  const { stateCapital, dispatchCapital } = useContext(CapitalContext);
  const [optionCheckbox, setoptionCheckbox] = useState<any>();
  const [optionCheckboxSave, setoptionCheckboxSave] = useState<any>();
  useEffect(() => {
    if (props?.dataDetail?.loanProductCode) {
      setoptionCheckboxSave(props?.dataDetail?.loanProductCode?.split(','));
    }
  }, [props?.dataDetail]);

  const [checkBtnLoading, setcheckBtnLoading] = useState(false);
  const [action, setAction] = useState<string>('hoạt động');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checked, setchecked] = useState(true);
  const [checkReloadImg, setCheckReloadImg] = useState<boolean>(false);
  const [checkDelete, setcheckDelete] = useState(false);
  const [setsaveImgReal, setsetsaveImgReal] = useState();
  const [form] = Form.useForm();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [amount, setamount] = useState<number | null>();

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const { Dragger } = Upload;
  const propsUpdate: UploadProps = {
    // listType: 'picture-card',
    onPreview: onPreview,
    name: 'file',
    multiple: true,
    maxCount: 1,

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    beforeUpload(file, FileList) {
      return false;
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status !== 'uploading') {
        const formData = new FormData();

        console.log(info.file);
        formData.append('file', info.file);

        UploadImgApi(
          formData,
          queryStringParams({
            packageType: 'FUND',
            partnerUuid: undefined,
          }),
        )
          .then((res) => {
            if (!res.error) {
              message.success(`${info.file.name} tải lên thành công`);
              if (res) {
                setsaveStringImg(res?.key);
              }
            } else {
              // message.error(`${info.file.name} file  deleted `);
              setsaveStringImg('');
            }
          })
          .catch((err) => {
            message.error(`${err} Err 404`);
          });
      }
    },
  };

  function handleCancelModalEdit() {
    setcheckDelete(false);
    setoptionCheckboxSave(props?.dataDetail?.loanProductCode?.split(','));
    dispatchCapital(updateModals({ ...stateCapital.modals, modalEdit: false }));
    props?.callApiDetailCapital();
  }

  const validateMessages = {
    required: 'Cần nhập ${label}!',
    types: {
      email: '${label} is not a valid email!',
      string: '${label} không hợp lệ!',
    },
  };

  const onChange = (key: string) => {
    console.log(key);
  };

  // function openModalConfirm() {
  //   dispatchCapital(updateModals({ ...stateCapital.modals, ModalOpenSaveInfor: true }));
  // }

  const handleCancelConfirmSave = () => {
    dispatchCapital(updateModals({ ...stateCapital.modals, ModalOpenSaveInfor: false }));
  };

  useEffect(() => {
    if (stateCapital?.modals.tabsModals == '1') {
      setamount(stateCapital?.modals?.saveCashin);
    }
    if (stateCapital?.modals.tabsModals == '2') {
      setamount(stateCapital?.modals?.saveCashout);
    } else {
      setamount(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateCapital?.modals]);

  const onFinish = async (values: any) => {
    if (values) {
      setcheckBtnLoading(true);
      await updateCapitalApi(props?.dataDetail?.code, {
        amount: stateCapital?.modals.saveAmountStore ? stateCapital?.modals.saveAmountStore : 0,
        enable: checked ? 'ACTIVE' : 'DISABLED',
        isCashIn: values?.amountCashIn ? true : false,
        loanProduct: optionCheckboxSave.join(),
        contract: props?.dataDetail?.contractFile ? (saveStringImg ? saveStringImg : props?.dataDetail?.contractFile) : 'Không có hình ảnh ',
      })
        .then((res) => {
          if (res?.error) {
            dispatchCapital(updateModals({ ...stateCapital.modals, modalEdit: false }));
            form.setFieldsValue({
              amountCashIn: undefined,
              amountCashOut: undefined,
              representName: '',
              loanProduct: '',
              contract: '',
            });
            setcheckDelete(false);
            setcheckBtnLoading(false);
          } else {
            dispatchCapital(updateModals({ ...stateCapital.modals, modalEdit: false }));
            setcheckDelete(false);
            setcheckBtnLoading(false);
            props?.callApiDetailCapital();
            form.setFieldsValue({
              amountCashIn: undefined,
              amountCashOut: undefined,
            });
            setCheckReloadImg(!checkReloadImg);
            notificationController.success({ message: `Sửa thành công` });
          }
        })
        .catch((err) => {
          notificationController.error({ message: _.get(err, `Create fail`, `${err} err API`) });
          dispatchCapital(updateModals({ ...stateCapital.modals, modalEdit: false }));
          setcheckDelete(false);
          setcheckBtnLoading(false);
        });
    }
  };

  const handleOk = () => {
    if (!checked) {
      setchecked(true);
      setAction('Đang hoạt động');
      dispatchCapital(updateModals({ ...stateCapital.modals, checkedActive: 'ACTIVE' }));
      setIsModalOpen(false);
    } else {
      setAction('Ngưng hoạt động');
      setchecked(false);
      dispatchCapital(updateModals({ ...stateCapital.modals, checkedActive: 'DISABLED' }));
      setIsModalOpen(false);
    }
    setIsModalOpen(false);
  };

  function handdleSwitch() {
    setIsModalOpen(true);
  }

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  function handleDelteImg() {
    setcheckDelete(true);
  }

  const callApiGetImg = async () => {
    if (props?.dataDetail) {
      await getImg(props?.dataDetail?.contractFile)
        .then((res) => {
          setsetsaveImgReal(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    if (props?.dataDetail) {
      callApiGetImg();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.dataDetail]);

  const callApiListProduct = async () => {
    const arr: any = [];
    await getProductApi()
      .then((res) => {
        if (res?.error) {
        } else {
          res &&
            res?.map((item: any) => {
              arr.push({ label: item?.name, value: item?.name });
            });
          setoptionCheckbox(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    form.setFieldsValue({
      checkBoxProduct: optionCheckboxSave,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionCheckboxSave]);

  const onChangeCheckbox = async (checkedValues: CheckboxValueType[]) => {
    await setoptionCheckboxSave(checkedValues);
    console.log('checkedValues', checkedValues);
  };

  useEffect(() => {
    callApiListProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Thông tin`,
      children: (
        <div className="box-content-body-take-border-bottom">
          <div className="box-content-body">
            <Col className="col-1">
              <Row className="label">Tên nguồn vốn</Row>
              <Form.Item name={'name'}>
                <Input disabled defaultValue={props?.dataDetail?.name} size={'small'} className={'style-input-create'} placeholder={'Nhập tên nguồn vốn'} />
              </Form.Item>
              <Row className="label">Tên đại diện</Row>
              <Form.Item name={'representName'}>
                <Input
                  disabled
                  defaultValue={props?.dataDetail?.representName ? props?.dataDetail?.representName : 'Không có tên đại diện'}
                  size={'small'}
                  className={'style-input-create'}
                  placeholder={'Nhập tên đại diện'}
                />
              </Form.Item>
              <Row className="label">Trạng thái</Row>
              <Row className="margin-top-10">
                <GS.SwitchStyle onClick={handdleSwitch} defaultChecked checked={checked} />
                <div className="text-action">{action}</div>
              </Row>
              <Row className="label label-type-loan">Loại khoản vay</Row>

              <S.StyleCheckboxCapital className="row-checkbox-type">
                <Form.Item name="checkBoxProduct" valuePropName="checked" label="loại khoản vay" rules={[{ required: true }]}>
                  <Checkbox.Group value={optionCheckboxSave} options={optionCheckbox && optionCheckbox} onChange={onChangeCheckbox} />
                </Form.Item>
              </S.StyleCheckboxCapital>
            </Col>
            <Col className="col-2">
              <Row className="label">
                <span className="icon-special">*</span> Chứng từ / hợp đồng
              </Row>
              {checkDelete ? (
                <div className="save-image box-img-content">
                  <ImgCrop>
                    <Dragger {...propsUpdate}>
                      <p className="ant-upload-drag-icon">
                        <IconUploadFile />
                      </p>
                      <p className="ant-upload-text">
                        Chọn hoặc kéo ảnh <span style={{ color: '#85A5FF' }}> hoá đơn chuyển khoản</span> vào đây để tải ảnh lên
                      </p>
                    </Dragger>
                  </ImgCrop>
                </div>
              ) : (
                <div>
                  <div className="save-image box-img-content">
                    <div className="box-img">
                      <img
                        className="style-img"
                        src={
                          setsaveImgReal && setsaveImgReal
                            ? setsaveImgReal
                            : 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.freepik.com%2Ffree-photos-vectors%2Floading-screen&psig=AOvVaw0A7H7rBe68YOeNx2IeudOj&ust=1677059194591000&source=images&cd=vfe&ved=0CBAQjRxqFwoTCJD27sGqpv0CFQAAAAAdAAAAABAI'
                        }
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="title-section-img">
                    <div className="box-startend">
                      <img src={IconImg} alt="" />
                      <div className="name-img">{props?.dataDetail && props?.dataDetail?.contractFile}</div>
                    </div>
                    <div>
                      <DeleteOutlined onClick={handleDelteImg} />
                    </div>
                  </div>
                </div>
              )}
            </Col>

            <GS.StyleModalConfirm title="Basic Modal" footer={null} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
              <div>
                <div className="text-confirm">{checked ? `Bạn có chắc muốn ngưng hoạt động nguồn vốn PVCOMBANK ?` : `Bạn có chắc muốn hoạt động nguồn vốn PVCOMBANK ?`} </div>
                <div className="btn-change">
                  <Button className="btn-cancel" onClick={handleCancel}>
                    Huỷ
                  </Button>
                  <Button className="btn-yes" onClick={handleOk}>
                    Xác nhận
                  </Button>
                </div>
              </div>
            </GS.StyleModalConfirm>
          </div>
        </div>
      ),
    },
    {
      key: '2',
      label: `Nạp, rút nguồn vốn`,
      children: <ContentMoneyTab form={form} dataDetail={props?.dataDetail ? props?.dataDetail : null}></ContentMoneyTab>,
    },
  ];

  return (
    <S.ModalEdit title="Sửa nguồn vốn" open={stateCapital?.modals?.modalEdit} onCancel={handleCancelModalEdit} footer={null}>
      <Form form={form} name="basic" onFinish={onFinish} validateMessages={validateMessages}>
        <GS.WrapperOverrideInput style={{ width: '100%', display: 'flex' }}>
          <div className="content">
            <S.TabStyle defaultActiveKey="1" items={items} onChange={onChange} />
          </div>
        </GS.WrapperOverrideInput>
        <GS.StyleModalConfirm title="Basic Modal" footer={null} open={stateCapital?.modals.ModalOpenSaveInfor} onCancel={handleCancelConfirmSave}>
          <div>
            <div className="text-confirm">{`Bạn có chắc muốn thêm 30.000.000 vào  nguồn vốn PVCOMBANK ?`} </div>
            <div className="btn-change">
              <Button className="btn-cancel" onClick={handleCancelConfirmSave}>
                Huỷ
              </Button>
              <Button className="btn-yes">Xác nhận</Button>
            </div>
          </div>
        </GS.StyleModalConfirm>
        <FooterModal type="submit" className="css-btn-yellow" handleClickCancel={handleCancelModalEdit} handleLoading={checkBtnLoading} labelCancel={'Hủy'} LabelSave={'Lưu nguồn vốn'}></FooterModal>
      </Form>
    </S.ModalEdit>
  );
}
