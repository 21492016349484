import React from 'react';
import { AppRouterInterface } from '@app/interfaces/interfaces';
import CustomerManagement from '@app/modules/Backline/Customers/pages/Management/CustomerManagement';
import Loan from './pages/Loan/LoanList';
import RequestLoanManagement from './pages/RequestLoan/RequestLoanManagement';
import Disbursement from './pages/Disbursement/Loan/Disbursement';
import DebtList from './pages/DebtList/Loan/DebtList';
import DetailLoan from './pages/Loan/DetailLoan/DetailLoan';
import DetailLoanConsolidated from './pages/LoanConsolidated/DetailLoan2/DetailLoan';

import DetailRequestLoan from './pages/RequestLoan/Detail/DetailRequestLoan';
import DetailCusomer from './pages/Management/DetailCustomer/DetailCusomer';
import InterestAndFee from './pages/InterestAndFee/InterestAndFee';
import ReconciliationLoan from './pages/Reconciliation/ReconciliationLoan/ReconciliationLoan';
import ReconciliationPayment from './pages/Reconciliation/ReconciliationPayment/ReconciliationPayment';
import DetailReconciliationPayment from './pages/Reconciliation/ReconciliationPayment/Detail/Detail';
import ReconciliationDisbursement from './pages/Reconciliation/ReconciliationDisbursement/ReconciliationDisbursement';
import DetailReconciliationLoan from './pages/Reconciliation/ReconciliationLoan/Detail/Detail';
import DetailReconciliationDisbursement from './pages/Reconciliation/ReconciliationDisbursement/Detail/Detail';
import Capital from './pages/Capital/Capital';
import BoxDetailCapital from './pages/Capital/Detail/DetailCapital';
import DetailWaitForDisbursementBoxProvider from './pages/Loan/DetailLoan/DetailWaitForDisbursement';
import Refund from './pages/Refund/Refund';
import ProductManagement from './pages/Product/Product';
import PolicyManagement from './pages/Policy/Policy';
import PartnerManagement from './pages/Partner/Partner';
import DetailPartner from './pages/Partner/Detail/DetailPartner';
import DetailPartnerProduct from './pages/Partner/Detail/DetailPartnerProduct';
import EkycManagement from './pages/EkycManagement/EkycManagement';
import DetailEkycManagement from './pages/EkycManagement/Detail/DetailEkycManagement';
import TabDisbursementDetailWaitForDisbursementBoxProvider from './pages/Disbursement/Loan/ComponentDisbursement/Detail/DetailWaitForDisbursement';

export const CustomerManagementRoutes: AppRouterInterface[] = [
  {
    path: 'management',
    element: <CustomerManagement />,
  },
  {
    path: `management/:id`,
    element: <DetailCusomer />,
  },
  {
    path: 'loan',
    element: <Loan />,
  },
  {
    path: 'request-loan-management',
    element: <RequestLoanManagement />,
  },
  {
    path: 'disbursement',
    element: <Disbursement />,
  },
  {
    path: '/customers/disbursement/detail-disbursement/:dateReview',
    element: <TabDisbursementDetailWaitForDisbursementBoxProvider />,
  },
  {
    path: 'debtList',
    element: <DebtList />,
  },
  {
    path: `loan/:loancode`,
    element: <DetailLoan />,
  },
  {
    path: 'loan-consolidated/:loancode',
    element: <DetailLoanConsolidated />,
  },
  {
    path: 'request-loan-management/name',
    element: <DetailRequestLoan />,
  },

  {
    path: 'interest-and-fee',
    element: <InterestAndFee />,
  },
  {
    path: 'loan/detail-disbursement/:dateReview',
    element: <DetailWaitForDisbursementBoxProvider />,
  },
  {
    path: 'refund',
    element: <Refund />,
  },
  {
    path: 'ekyc-management',
    element: <EkycManagement />,
  },
  {
    path: 'ekyc-management/:code',
    element: <DetailEkycManagement />,
  },
];

export const ReconciliationLoanRoutes: AppRouterInterface[] = [
  {
    path: 'loan',
    element: <ReconciliationLoan />,
  },

  {
    path: `loan/:date`,
    element: <DetailReconciliationLoan />,
  },
  {
    path: 'disbursement',
    element: <ReconciliationDisbursement />,
  },
  {
    path: 'disbursement/:date',
    element: <DetailWaitForDisbursementBoxProvider />,
  },

  {
    path: `disbursement/:date`,
    element: <DetailReconciliationDisbursement />,
  },
  {
    path: 'payment/',
    element: <ReconciliationPayment />,
  },
  {
    path: `payment/:date`,
    element: <DetailReconciliationPayment />,
  },
];

export const CapitalRoutes: AppRouterInterface[] = [
  {
    path: 'management',
    element: <Capital />,
  },
  {
    path: `management/:id`,
    element: <BoxDetailCapital />,
  },
];
export const ProductRoutes: AppRouterInterface[] = [
  {
    path: 'management',
    element: <ProductManagement />,
  },
];

export const PolicyRoutes: AppRouterInterface[] = [
  {
    path: 'management',
    element: <PolicyManagement />,
  },
];

export const PartnerRoutes: AppRouterInterface[] = [
  {
    path: 'management',
    element: <PartnerManagement />,
  },
  {
    path: 'management/:uuid',
    element: <DetailPartner />,
  },
  {
    path: 'management/:uuid/:productName',
    element: <DetailPartnerProduct />,
  },
];
